.modal_delete_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 32vh; /* Initial min height */
    max-width: 90%; /* Responsive max width */
    width: 400px; /* Fixed width for larger screens */
    padding: 2rem; /* Padding inside the modal */
    margin: auto; /* Centers the modal */
    box-sizing: border-box; /* Includes padding in the width */
}

.modal_delete_content p {
    font-weight: 600;
    font-size: 2rem; /* Font size */
    line-height: 2.5rem; /* Adjusted line height */
    margin-bottom: 2rem; /* Bottom margin for spacing */
    text-align: center;
}

.modal_delete_content .modal_group_buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns for buttons */
    gap: 1.2rem; /* Gap between buttons */
    width: 100%; /* Full width */
    padding: 0; /* No padding to avoid excess spacing */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 895px) {
    .modal_delete_content {
        min-height: 30vh; /* Reduced min height for smaller screens */
        height: auto; /* Allow height to adjust based on content */
        width: 90%; /* Responsive width for smaller screens */
        max-width: 400px; /* Limit the maximum width to avoid stretching */
        padding: 1.6rem; /* Adjust padding */
    }

    .modal_delete_content p {
        font-size: 1.6rem; /* Font size adjustment */
        line-height: 2rem; /* Line height adjustment */
        margin-bottom: 1.6rem; /* Margin adjustment */
    }

    .modal_delete_content .modal_group_buttons {
        grid-template-columns: 1fr; /* Stack buttons on smaller screens */
        gap: 1rem; /* Gap adjustment */
    }
}

@media (max-width: 480px) {
    .modal_delete_content {
        min-height: 25vh; /* Further reduced min height */
        height: auto; /* Allow height to adjust based on content */
        width: 95%; /* Increased width for very small screens */
        max-width: 360px; /* Limit the maximum width to prevent stretching */
    }

    .modal_delete_content p {
        font-size: 1.4rem; /* Font size adjustment */
        line-height: 1.8rem; /* Line height adjustment */
    }

    .modal_delete_content .modal_group_buttons {
        padding: 0; /* No padding for buttons */
        gap: 0.8rem; /* Reduce gap for very small screens */
    }
}
