.upload_file_wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.file_upload_input[type="file"] {
  display: none;
}
.preview_image {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
  width: 100%;
}
.preview_image img {
  width: 100%;
  height: 12.5rem;
  object-fit: cover;
}
/* .preview_image img {
    max-width     : 100%;
    object-fit: cover;
} */
