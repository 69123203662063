.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw; 
    height: 100vh; 
    background-color: rgba(0, 0, 0, 0.7); 
    z-index: 1040; 
    display: flex;
    align-items: center; 
    justify-content: center; 
}

.modal-content {
    background-color: var(--gray-8); 
    border-radius: 15px; 
    padding: 3rem 4.2rem; 
    min-height: 50vh; 
    width: 90%; 
    max-width: 600px; 
    box-sizing: border-box; 
}

.modal-content.view {
    max-width: 80vw; 
}

.modal-main {
    padding-top: 3rem; 
    max-height: 70vh;
    overflow-x: hidden; 
    overflow-y: auto; 
    -ms-overflow-style: none; 
    scrollbar-width: none; 
}

.modal-main::-webkit-scrollbar {
    display: none;
}

@media (max-width: 768px) {
    .modal-content {
        padding: 2rem;
        width: 90%; 
        max-width: none; 
        min-height: 40vh; 
    }
}

@media (max-width: 480px) {
    .modal-content {
        padding: 1.5rem; 
        min-height: 50vh; 
    }

    .modal-main {
        padding-top: 2rem; 
    }
}
